"use client";
import { InformationalModalConfig } from "src/modals/configs/InformationalModal";
import RootModalContainer from "src/modals/containers/RootModalContainer";
import InformationalHome from "src/screens/(informational)/InformationalHome";

const Page = () => {
	return (
		<>
			<InformationalHome />
			<RootModalContainer config={InformationalModalConfig} />
		</>
	);
};

export default Page;
