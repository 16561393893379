import IntroSection from "../components/IntroSection/IntroSection.web";

const InformationalHome = () => {
	return (
		<IntroSection
			header={["The easiest way to", "share photo albums"]}
			body={"Never miss your photos"}
		/>
	);
};
export default InformationalHome;
